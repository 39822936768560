import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, {}>((theme) => ({
    videoRectangle:{
        width: '52.25rem',
        height: '29.313rem',

        margin:"2.25rem 0 0 1.5rem",
        "& iframe": {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
          },

    },
    videoRectangleMobile:{
        width: '20.938rem',
        height: '11.746rem',
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        margin:"2.25rem auto 0",
        "& iframe": {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
          },

    },
    missionContent:{
        width: '52.25rem',

        margin:"2.25rem 0 6rem 1.5rem",
        display: "flex",
        justifyContent: "space-between",

    },leftMissionContent:{



    },RightMissionContent:{

        display: "flex",
        alignSelf: "flex-end",
        [theme.breakpoints.down(1280)]: {
            width: "3rem",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            margin:"2rem 0 0 0"
          },
    },
    SimulatorTitle:{
        fontFamily: "Montserrat",
        fontSize: "1.25rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        textAlign: "left",
        color:"#393e44",

    },
    SimulatorP1:{
        fontFamily: "Montserrat",
        fontSize: "0.75rem",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.67,
        letterSpacing: "normal",
        textAlign: "left",
        color:"#393e44",
        marginTop:"0.75rem",
        [theme.breakpoints.down(1280)]: {
            textAling:"center",

          },
    },
    SimulatorP2:{
        fontFamily: "Montserrat",
        fontSize: "0.75rem",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.67,
        letterSpacing: "normal",
        textAlign: "left",
        color:"#393e44",
        marginBottom: "1.25rem",

    },
    goToEmFund:{
        marrgin:"1rem 0 0 0"
    },
    mobileContainer:{
        display: "flex",
        flexDirection:"column",
        margin:"2rem auto ",
        width:"20.938rem",

    },
}));