import React from "react";
import { PageProps } from "gatsby";

import MissionA3 from "@components/Missions/SavingAndInvestment/M3A/M3A";
import { useAuth } from "@hooks";

const savingInvestmentMA3: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  return (
    <>
      <MissionA3 />
    </>
  );
};

export default savingInvestmentMA3;
