import React, { useContext, useState } from "react";
import { navigate } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./M3A.style";
import { ButtonPrimary } from "@components";
import {
  LayoutMissionDesktop,
  LayoutMissionMobile,
} from "@components/Structural";
import { completeMissionMutation, getUserMissions } from "@apollo";
import { MissionsInfo } from "@data";
import { useApolloClient } from "@apollo/client";
import { AppContext } from "@context";
import { nextMissionModalInfo } from "@utils";
import { Route } from "@interfaces";

const MissionA3 = () => {
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const classes = useStyles();
  const [step, isStep] = useState(0);
  const { userMissions } = getUserMissions();
  const client = useApolloClient();
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);
  const videoUrl = "om4iJVBLzPA";
  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.SavingsInvestmentCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.SavingsInvestmentCategory.m2.id
  )[0];

  const handleClick = () => {
    console.log(userMissions, categoryMissions, currentMission);
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(
        nextMissionModalInfo(currentMission, categoryMissions)
      );
    } catch (error) {
      // console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  return (
    <>
      <>
        {!isMobile && (
          <>
            <LayoutMissionDesktop
              title={"Cómo me preparo para emergencias"}
              number={2}
              confettiFire={confettiFire}
              subtitle={"¿Qué ahorro es fundamental y urgente?"}
              onHouseClick={() => navigate(Route.savingsAndInvestment)}
            >
              <div className={classes.videoRectangle}>
                <iframe
                  width="auto"
                  height="auto"
                  src={`https://www.youtube.com/embed/${videoUrl}?modestbranding=1&rel=0`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>

              <div className={classes.missionContent}>
                <div className={classes.leftMissionContent}>
                  {/**<h3 className={classes.SimulatorTitle}>Simulador</h3>
                    <p className={classes.SimulatorP1}>te ayudaremos a saber cuanto debe ser tu fondo de</p>
                    <p className={classes.SimulatorP2}>emergencia en base a tus gastos promedios mensuales.</p>
                    <ButtonPrimary
                        startIcon={<NearMe />}
                        onClick={() => {}}
                        text="Ir a tu fondo de emergencia"
                        noDegraded
                        className={classes.goToEmFund}
                    />*/}{" "}
                  {/* Esta es la parte de simulaciones, que permanecera oculto hasta nuevo aviso*/}
                </div>

                <div className={classes.RightMissionContent}>
                  <ButtonPrimary
                    onClick={() => {
                      handleClick();
                    }}
                    text="Terminar misión"
                    secondaryVersion
                    noDegraded
                  />
                </div>
              </div>
            </LayoutMissionDesktop>
          </>
        )}

        {isMobile && (
          <>
            <LayoutMissionMobile
              title={"Cómo me preparo para emergencias"}
              number={2}
              subtitle="¿Qué ahorro es fundamental y urgente?"
              confettiFire={false}
              onHouseClick={() => navigate(Route.savingsAndInvestment)}
            >
              <div className={classes.videoRectangleMobile}>
                <iframe
                  width="auto"
                  height="auto"
                  src={`https://www.youtube.com/embed/${videoUrl}?modestbranding=1&rel=0`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>

              <div className={classes.mobileContainer}>
                {/*<h3 className={classes.SimulatorTitle}>Simulador</h3>
                        <p className={classes.SimulatorP1}>
                            te ayudaremos a saber cuanto debe ser tu fondo de emergencia en base a tus gastos promedios mensuales.
                        </p>                        
                        <ButtonPrimary
                            startIcon={<NearMe />}
                            onClick={() => {}}
                            text="Ir a tu fondo de emergencia"
                            noDegraded
                            className={classes.goToEmFund}
                        />*/}
                {/* Esta es la parte de simulaciones, que permanecera oculto hasta nuevo aviso}*/}

                <div className={classes.RightMissionContent}>
                  <ButtonPrimary
                    onClick={() => {
                      handleClick();
                    }}
                    text="Terminar misión"
                    secondaryVersion
                    noDegraded
                  />
                </div>
              </div>
            </LayoutMissionMobile>
          </>
        )}
      </>
    </>
  );
};

export default MissionA3;
